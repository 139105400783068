/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-invalid-this */

import { PREFIX, SIDEBAR_MODAL_OPEN_CLASS } from '../config'
import { commonVariable } from '../globalConstants/variableDeclaration.constant'

const bdsUniversalsearchComponent = (): void => {
    const $ = window.$

    const x = document.getElementsByTagName('BODY')[0]
    const resultBanner = x.getAttribute('data-banner')
    const searchPageId = 'Brandspage'
    const searchPopUp = 'Brands'
    setTimeout(() => {
        const bannerBrandList = sessionStorage.getItem('bannerBrands')
            ? $.parseJSON(sessionStorage.getItem('bannerBrands') as string)
            : []
        const brandConsumerList = sessionStorage.getItem('consumerBrand')
            ? $.parseJSON(sessionStorage.getItem('consumerBrand') as string)
            : []

        const urlParams = new URLSearchParams(window.location.search)
        const myParam = urlParams.get('q') as string
        const brandname = urlParams.get('brand')
        let brandDropDownList

        $(document).ready(function () {
            if (bannerBrandList) {
                if (brandConsumerList && brandConsumerList.length > 0) {
                    const brandMergedList = [...bannerBrandList, ...brandConsumerList]
                    const grades = new Map()
                    brandMergedList.forEach(function (item) {
                        const brandItemId = item?.['brandId']
                        grades.set(JSON.stringify(brandItemId), item)
                    })
                    brandDropDownList = [...grades.values()]
                } else {
                    brandDropDownList = bannerBrandList
                }
            }
        })
        if (resultBanner && brandDropDownList) {
            addBrandToDropdown(brandDropDownList, 'Brands')
            defaultselection(resultBanner, brandDropDownList, 'Brands')
            addBrandToDropdown(brandDropDownList, 'Brandspage')
            dropDownLoader(searchPageId, 'selectinputPage')
            dropDownLoader(searchPopUp, 'searchinput')
            if (myParam && brandname) {
                onSearchPageLoad()
            }
        }

        /**
         * This function opens searchpopup
         * @param {string} deviceType scroll handle based on param
         */
        function openDiv() {
            $(commonVariable.searchwrapperdiv).show()
            $('body').addClass(SIDEBAR_MODAL_OPEN_CLASS)
            $(commonVariable.searchMobileIconContainer).first().addClass('open')
            $(commonVariable.searchInput).focus()
        }

        /**
         * This function closes searchpopup
         */
        function closeDiv() {
            $(commonVariable.searchwrapperdiv).hide()
            $('body').removeClass(SIDEBAR_MODAL_OPEN_CLASS)
            $(commonVariable.searchMobileIconContainer).first().removeClass('open')
            clearSearch()
        }

        /**
         * This function clears search form
         */
        function clearSearch() {
            if (resultBanner && brandDropDownList) {
                $(commonVariable.searchpopupdiv).html('')
                defaultselection(resultBanner, brandDropDownList, 'Brands')
                $(commonVariable.searchInput).val('')
            }
        }

        $(`.${PREFIX}-brand-navigation__search-icon, 
            .${PREFIX}-universal-navigation__right-part__listing .${PREFIX}__searchicon`).on('click', openDiv)

        $(`.${PREFIX}-search__close, 
            .${PREFIX}-search__searchPopUpContainer`).on('click', closeDiv)
        $(`.${PREFIX}-search__box`).on('click', function (e: Event) {
            e.stopPropagation()
        })

        // eslint-disable-next-line sonar/no-reference-error
        $(document).keyup(function (e: JQuery.KeyUpEvent) {
            if (e.key === 'Escape') {
                closeDiv()
            }
        })

        $('.nl-search__field').click(function () {
            $(this).find('input').focus()
        })

        /**
         * drowpdownfunctionality
         * @param {any} id dropdown fuction for particular parent div
         *  @param {any} inputId input searchbox id
         */
        function dropDownLoader(id: any, inputId: any) {
            document
                .querySelector(`#${id} .dropdownProfiles .select-wrapper`)
                ?.addEventListener('click', function (this: any) {
                    this.querySelector(`#${id} .dropdownProfiles .select`).classList.toggle('open')
                })
            for (const option of document.querySelectorAll(`#${id} .dropdownProfiles .custom-option`) as any) {
                option.addEventListener('click', function (this: any) {
                    if (!this.classList.contains('selected')) {
                        this.parentNode.querySelector(`#${id} .custom-option.selected`)?.classList.remove('selected')
                        this.classList.add('selected')
                        this.closest('.select').querySelector(`#${id} ${commonVariable.selectText}`).textContent =
                            this.textContent
                        const selectedband = $(`#${id}`).find(commonVariable.customselect).attr('id')
                        const inputPopupvalue = (document.getElementById(inputId) as HTMLInputElement).value

                        if (inputPopupvalue.length > commonVariable.two && inputId === 'searchinput') {
                            $(commonVariable.searchpopupdiv).html('')
                            callapi(inputPopupvalue, selectedband)
                        }
                    }
                })
            }

            window.addEventListener('click', function (e) {
                const select = document.querySelector(`#${id} .dropdownProfiles .select`)
                if (!select?.contains(e.target as Node)) {
                    select?.classList.remove('open')
                }
            })
        }

        /**
         * Search page load
         */
        function onSearchPageLoad() {
            $('#selectinputPage').val(myParam)
            if (brandname) {
                defaultselection(brandname, brandDropDownList, 'Brandspage')
            }
            callapipage(myParam, brandname)
        }

        $('input#searchinput').keyup(function (this: any, event: any) {
            $(commonVariable.searchpopupdiv).html('')
            if ($(this).val().length > commonVariable.two) {
                const selectedband = $('#Brands').find(commonVariable.customselect).attr('id')
                callapi($(this).val(), selectedband)
                if (event.key === 'Enter') {
                    const el = document.getElementsByClassName('nl-search__button searchPopUp')[0] as HTMLElement
                    el.click()
                }
            }
        })

        $('input#selectinputPage').keyup(function (this: any, event: any) {
            if (event.key === 'Enter') {
                const el = document.getElementsByClassName('nl-search__button searchLanding')[0] as HTMLElement
                el.click()
            }
        })

        /**
         * search result text for search page
         * @param {any} updateSearchText json searchresponse
         */
        function updatesearchTemplate(updateSearchText: any) {
            $(commonVariable.searchInputdiv).html('')
            let dataresultPage = {}
            dataresultPage = updateSearchText.results
            const resultTotal = updateSearchText.resultTotal
            const mysearchterm = updateSearchText.searchTerm

            $(
                `<div class="nl-search__Results_info">Showing ${resultTotal} results for <b>${mysearchterm}</b></div>`,
            ).appendTo(commonVariable.searchInputdiv)
            $(dataresultPage).each(function (_key: any, val: any) {
                const newDiv = $('<div class="nl-search__Results_brands"/>').css({
                    display: 'block',
                })
                newDiv.appendTo($(commonVariable.searchInputdiv))
                $('#nav').html('')

                const description = val.description ? makebold(val.description, mysearchterm) : ''
                const brandVal = val.brand ? makebold(val.brand, mysearchterm) : ''
                const brandtitle = val.title ? makebold(val.title, mysearchterm) : ''

                const newHTML = `<p class="nl-search__brandName">${brandVal}</p>
                    <p class="nl-search__result-details">
                    <a href=${val.path}.html>${brandtitle}</a>
                    </p>
                    <p class="nl-search__info">${description}</p>`

                $(newDiv).html(newHTML)
                $('.nl-search__Results_brand').append($(newDiv))
            })
            pagination()
        }

        /**
         * search result page template No Result
         * @param {any} noresultText jno result attribute
         */
        function updateNoResultText(noresultText: any) {
            $(commonVariable.searchInputdiv).html('')
            const newDiv = $(`<div class="nl-search__Results_info">${noresultText}</div>`).css({
                display: 'block',
            })
            newDiv.appendTo($(commonVariable.searchInputdiv))
        }

        /**
         * search result higlight text
         * @param {any} results  higlight list
         * @param {any} term search term
         * @return {any} bold appended text
         */
        function makebold(results: any, term: any) {
            return results.replace(new RegExp(term, 'gi'), match => `<b>${match}</b>`)
        }

        /**
         * search result text for search popup
         * @param {any} updatedText json searchresponse
         */
        function updateTemplate(updatedText: any) {
            $(commonVariable.searchpopupdiv).html('')
            let dataUpdate = {}
            dataUpdate = updatedText.results
            const inputparam = updatedText.searchTerm

            $(`<div class="nl-search__Results_info">Quick search results for <b>${inputparam}</b></div>`).appendTo(
                commonVariable.searchpopupdiv,
            )

            $(dataUpdate).each(function (_key: any, val: any) {
                const newDiv = $('<div class="nl-search__Results_brands"/>').css({
                    display: 'block',
                })

                newDiv.appendTo($(commonVariable.searchpopupdiv))
                const popupBrand = val.brand ? makebold(val.brand, inputparam) : ''
                const popBrandtitle = val.title ? makebold(val.title, inputparam) : ''

                const newHTML = `<p class="nl-search__brandName">${popupBrand}</p><p class="nl-search__result-details">
                    <a href=${val.path}.html>${popBrandtitle}</a></p>`

                $(newDiv).html(newHTML)
                $('.nl-search__Results_brand').append($(newDiv))
            })
        }

        /**
         * search result page template No Result
         * @param {any} noresultText jno result attribute
         */
        function updateNoResultPopUp(noresultText: any) {
            $(commonVariable.searchpopupdiv).html('')
            const newDiv = $(`<div class="nl-search__Results_info">${noresultText}</div>`).css({
                display: 'block',
            })
            newDiv.appendTo($(commonVariable.searchpopupdiv))
        }

        /**
         * Api call for search in popup
         * @param {any} data input text
         * @param {any} dropdowndata brand list
         */
        function callapi(data: any, dropdowndata: any) {
            const apicall = document.getElementById('searchPage')
            const showQuickResults = apicall?.getAttribute('data-showQuickResults')
            const apicallpath = apicall?.getAttribute(commonVariable.apicallpath)
            const quickResultLimit = apicall?.getAttribute(commonVariable.quickResultLimit)
            const noResultText = apicall?.getAttribute(commonVariable.noResultFound)

            // eslint-disable-next-line no-void
            void $.ajax({
                type: 'GET',
                dataType: 'text',
                url: `${apicallpath}?q=${data}&brand=${dropdowndata}&showQuickResults=${showQuickResults}&quickResultLimit=${quickResultLimit}`,
                success: function (searchresult: any) {
                    if (searchresult !== null) {
                        const updateSearchText = $.parseJSON(searchresult)
                        const resultTotal = updateSearchText.resultTotal

                        if (resultTotal && resultTotal > 0) {
                            updateTemplate($.parseJSON(searchresult))
                        } else {
                            updateNoResultPopUp(noResultText)
                        }
                    } else {
                        updateNoResultPopUp(noResultText)
                    }
                },
            })
        }
        $('.searchPopUp').on('click', function () {
            const searchPage = document.getElementById('searchPage')
            const searchresultpath = searchPage?.getAttribute('data-searchresultpath')
            const name = $(commonVariable.searchInput).val() as string
            const brand = $('#Brands').find(commonVariable.customselect).attr('id')
            // eslint-disable-next-line sonar/different-types-comparison
            if (name !== undefined && name !== null && name.length > commonVariable.two) {
                window.location.href = `${searchresultpath}.html?q=${name}&brand=${brand}`
            }
        })

        $('.searchLanding').on('click', function () {
            const name = $('#selectinputPage').val() as string | string[]
            const brand = $('#Brandspage').find('.custom-options .custom-option.selected').attr('id')
            // eslint-disable-next-line sonar/different-types-comparison
            if (name !== undefined && name !== null && name.length > commonVariable.two) {
                $('#searchDivpage').html('')
                $('#nav').html('')
                callapipage(name, brand)
            }
        })

        /**
         * Api call for search in search page
         * @param {any} data input text
         * @param {any} selectedBrand brand list
         */
        function callapipage(data: any, selectedBrand: any) {
            const searchPage = document.getElementById('searchPage')
            const apicallpath = searchPage?.getAttribute(commonVariable.apicallpath)
            const quickResultLimit = searchPage?.getAttribute(commonVariable.quickResultLimit)
            const noResultText = searchPage?.getAttribute(commonVariable.noResultFound)
            // eslint-disable-next-line no-void
            void $.ajax({
                type: 'GET',
                dataType: 'text',
                url: `${apicallpath}?q=${data}&brand=${selectedBrand}&showQuickResults=false&quickResultLimit=${quickResultLimit}`,
                success: function (searchresult: any) {
                    if (searchresult !== null) {
                        const updateSearchText = $.parseJSON(searchresult)
                        const resultTotal = updateSearchText.resultTotal

                        if (resultTotal && resultTotal > 0) {
                            updatesearchTemplate($.parseJSON(searchresult))
                        } else {
                            updateNoResultText(noResultText)
                        }
                    } else {
                        updateNoResultText(noResultText)
                    }
                },
            })
        }

        /**
         * Add list in the dropdown
         * @param {any} brandList input text
         * @param {any} id list parent id
         */
        function addBrandToDropdown(brandList: any, id: any) {
            if (brandList.length === commonVariable.two || id === 'preferedBrandDropdown') {
                $.each(brandList, function (_key: any, value: any) {
                    if (value.brandId !== 'bds' && value.brandId !== 'BDS') {
                        $(`#${id} .custom-options`).append(
                            `<span class="custom-option" id=${value.brandId}>${value.brandName}</span>`,
                        )
                    }
                })
            } else {
                $.each(brandList, function (_key: any, value: any) {
                    $(`#${id} .custom-options`).append(
                        `<span class="custom-option" id=${value.brandId}>${value.brandName}</span>`,
                    )
                })
            }
        }

        /**
         * Selected brand dropdown
         * @param {any} defaultresult default selection
         * @param {any} brandDropDown dropdown list
         * @param {any} selectedId parent id
         */
        function defaultselection(defaultresult: any, brandDropDown: any, selectedId: any) {
            if (brandDropDown && brandDropDown.length === commonVariable.two) {
                $.each(brandDropDown, function (_key: any, value: any) {
                    if (value.brandId !== 'bds' && value.brandId !== 'BDS') {
                        $(`#${selectedId} ${commonVariable.customoption}${value.brandId}`).addClass('selected')
                        if (document.querySelector(`#${selectedId} ${commonVariable.selectText}`)) {
                            const element = document.querySelector(
                                `#${selectedId} ${commonVariable.selectText}`,
                            ) as HTMLElement
                            element.textContent = $(
                                `#${selectedId} ${commonVariable.customoption}${value.brandId}`,
                            ).text()
                        }
                    }
                })
            } else {
                if (brandDropDown) {
                    $.each(brandDropDown, function (_key: any, value: any) {
                        $(`#${selectedId} ${commonVariable.customoption}${value.brandId}`).removeClass('selected')
                    })
                }
                $(`#${selectedId} ${commonVariable.customoption}${defaultresult}`).addClass('selected')
                if (document.querySelector(`#${selectedId} ${commonVariable.selectText}`)) {
                    const element = document.querySelector(`#${selectedId} ${commonVariable.selectText}`) as HTMLElement
                    element.textContent = $(`#${selectedId} ${commonVariable.customoption}${defaultresult}`).text()
                }
            }
        }

        /**
         *  Desktop Pagination
         */
        function pagination() {
            const items = $('#searchDivpage .nl-search__Results_brands')

            if (items && items.length > 0 && window.screen.width > commonVariable.mobileWidth) {
                $('#searchDivpage').after("<div class='nl-search__navigation' id='nav'>")

                const numItems = items.length
                const perPage = 10
                if (numItems > perPage) {
                    items.slice(perPage).hide()

                    $('#nav').pagination({
                        items: numItems,
                        itemsOnPage: perPage,
                        prevText: 'PREVIOUS',
                        nextText: 'NEXT',
                        onPageClick: function (pageNumber) {
                            const showFrom = perPage * (pageNumber - commonVariable.one)
                            const showTo = showFrom + perPage
                            $('#searchDivpage .nl-search__Results_brands').hide().slice(showFrom, showTo).show()
                        },
                    })

                    $(window).bind('popstate', checkFragment)

                    checkFragment()
                }
            }
        }

        /**
         *  on page refresh path pagination
         */
        function checkFragment() {
            // If there's no hash, treat it like page 1.

            let hash: RegExpExecArray | null | string = window.location.hash || '#page-1'
            const regex = /^#page-(\d+)$/
            // We'll use a regular expression to check the hash string.
            hash = regex.exec(hash)

            if (hash) {
                $('#nav').pagination('selectPage', parseInt(hash[commonVariable.one], 10))
            }
        }
    }, commonVariable.thousand)
}

export { bdsUniversalsearchComponent }
