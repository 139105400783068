/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-invalid-this */

import { commonVariable } from '../globalConstants/variableDeclaration.constant'
import { componentDimensions } from '../globalConstants/componentDimensions'
import { PREFIX } from '../config'

const bdsPageLoadComponent = (): void => {
    const $ = window.$
    const header = document.getElementById('bdsNavigationBar')
    let sticky: any
    if (header) {
        sticky = header.offsetTop
    }
    window.onscroll = function () {
        myFunction(header, sticky)
    }
    /**
     * This function will add/remove backgroud scroll
     *  @param {any} headerData The header offset.
     *  @param {any} stickyBar The stickyBar top offset.
     */
    function myFunction(headerData: any, stickyBar: any) {
        if (headerData) {
            if (window.pageYOffset > stickyBar) {
                headerData.classList.add('nl-primary-navigation__sticky')
            } else {
                headerData.classList.remove('nl-primary-navigation__sticky')
            }
        }
    }
    let bind = true
    $(document).ready(function () {
        $('#accountButtons').click(function (this: any, event: any) {
            event.stopPropagation()
            bind = !bind
        })

        // Prevents menu from closing when clicked inside
        if (document.getElementById('bdsAccountsMenu')) {
            document.getElementById('bdsAccountsMenu')?.addEventListener('click', function (event) {
                event.stopPropagation()
            })
        }

        $('#uni-menu ul li.sub-menu').hover(function (this: any) {
            if (bind) {
                $(this).children('ul').stop(String(true), false, true).fadeToggle(commonVariable.fadeTime)
            }
        })

        $(document).click(function () {
            hidemenuBrandList()
        })

        $(document).on('scroll', function () {
            hidemenuBrandList()
        })

        /**
         * This function hide menu brand list
         */
        function hidemenuBrandList() {
            $(commonVariable.accountMenuTag).hide()
            bind = true
            $('a.btn').removeClass('active')
        }
        $('#responsiveuniversalmenu a').click(function (this: any) {
            $('a.responsive-link').removeClass('active')
            $(this).addClass('active')
        })

        $('.nl-brand-responsive-menu a').click(function (this: any) {
            $('a').removeClass('active')
            $(this).addClass('active')
        })

        $('#uni-menu a').click(function (this: any) {
            $('a.btn').removeClass('active')
            $(this).addClass('active')
            if (bind) {
                $('#accountButtons').removeClass('active')
            }
        })

        $('#brand-nav a').click(function (this: any) {
            $('a.brandnav').removeClass('active')
            $(this).addClass('active')
        })

        $('#brands-click a').click(function (this: any) {
            $('a.brand-item').removeClass('active')
            $(this).addClass('active')
        })

        $('#consumer-click a').click(function (this: any) {
            $('a.brand-item').removeClass('active')
            $(this).addClass('active')
        })

        $('#banner-brands-click a').click(function (this: any) {
            $('a.brands-item').removeClass('active')
            $(this).addClass('active')
        })

        $('#consumer-brands-click a').click(function (this: any) {
            $('a.brands-item').removeClass('active')
            $(this).addClass('active')
        })

        $('#hamburgerClick').click(function (event: any) {
            event.preventDefault()
            document.getElementById('hamburger-menulist')?.classList.toggle('showmenu')
            document.body.classList.toggle('sidebar-modal-open')
        })

        $('#hamburgerprimaryClick').click(function () {
            document.getElementById('hamburger-menulist')?.classList.toggle('showmenu')
            document.body.classList.toggle('sidebar-modal-open')
            $(commonVariable.brandMenuDropDown).addClass('showbrandmenu')
        })

        let brandcount = 0
        let profilecount = 0
        $('#ourBrandClick').click(function () {
            profilecount = 0
            if (brandcount === 0) {
                $(commonVariable.brandMenuDropDown).addClass('showbrandmenu')
                $(commonVariable.universalProfileDropown).removeClass('showbrandmenu')
                $(commonVariable.responsiveMenu).hide()
                brandcount = commonVariable.one
            } else {
                $(commonVariable.brandMenuDropDown).removeClass('showbrandmenu')
                $(commonVariable.universalProfileDropown).removeClass('showbrandmenu')
                $('#ourBrandClick').removeClass('active')
                $(commonVariable.responsiveMenu).show()
                brandcount = 0
            }
            if ($(commonVariable.mobileSecondaryNavigation).is(':visible')) {
                if ($(commonVariable.brandMenuDropDown).hasClass('showbrandmenu')) {
                    $(commonVariable.hamburgerMenulist).removeClass(commonVariable.mobileSecondaryNavigationHeight)
                } else {
                    $(commonVariable.hamburgerMenulist).addClass(commonVariable.mobileSecondaryNavigationHeight)
                }
            }
        })
        $('#profileMobileUnviversal').click(function () {
            brandcount = 0
            if (profilecount === 0) {
                $(commonVariable.universalProfileDropown).addClass('showbrandmenu')
                $(commonVariable.brandMenuDropDown).removeClass('showbrandmenu')
                $(commonVariable.responsiveMenu).hide()
                profilecount = commonVariable.one
            } else {
                $(commonVariable.brandMenuDropDown).removeClass('showbrandmenu')
                $(commonVariable.universalProfileDropown).removeClass('showbrandmenu')
                $('#profileMobileUnviversal').removeClass('active')
                $(commonVariable.responsiveMenu).show()
                profilecount = 0
            }
            if ($(commonVariable.mobileSecondaryNavigation).is(':visible')) {
                if ($(commonVariable.universalProfileDropown).hasClass('showbrandmenu')) {
                    $(commonVariable.hamburgerMenulist).removeClass(commonVariable.mobileSecondaryNavigationHeight)
                } else {
                    $(commonVariable.hamburgerMenulist).addClass(commonVariable.mobileSecondaryNavigationHeight)
                }
            }
        })

        $(
            // eslint-disable-next-line max-len
            '.nl-brand-navigation__responsive-toggle-menu-fourth-part .nl-brand-navigation__responsive-menu-expand-collapse',
        ).click(function (this: any) {
            $(this).toggleClass('clicked')
        })

        /*
         * Secondary Navigation Script
         */
        const activeLinkItems = $(`.${PREFIX}-secondary-navigation a.cmp-navigation__item-link`).filter(function (
            this: any,
        ) {
            return $(this).attr('href') === window.location.pathname
        })
        const isBrandNavPageAnchorsPresent = $(`.${PREFIX}-brand-responsive-menu ul a`).filter(function (this: any) {
            return $(this).attr('href') === window.location.pathname
        })

        if (activeLinkItems.length || isBrandNavPageAnchorsPresent.length) {
            $(commonVariable.responsiveMenu).children().hide()
            const secondaryNavElement = $(`.${PREFIX}-secondary-navigation`).clone()
            $(commonVariable.mobileSecondaryNavigation).show()
            $(commonVariable.mobileSecondaryNavigation).append(secondaryNavElement)
            $(commonVariable.hamburgerMenulist).addClass(commonVariable.mobileSecondaryNavigationHeight)

            $('.cmp-navigation__item--active > ul').slideToggle(commonVariable.twoHundred)
            $('.cmp-navigation__item--active > .secondary-navigation-icon').addClass('open')
            activeLinkItems.addClass('cmp-navigation__item-link--active')
        }

        $(`.${PREFIX}-secondary-navigation .cmp-navigation__item`).each(function (this: any) {
            hideIcon.call(this)
        })

        $(`.${PREFIX}-secondary-navigation .cmp-navigation__item > .secondary-navigation-icon`).click(function (
            this: any,
            event: any,
        ) {
            toggleSecondaryIcons.call(this, event)
        })

        /**
         * This function hide icons
         * @param {any} this icon id for hiding
         */
        function hideIcon(this: any) {
            if (!$(this).has('ul').length) {
                $(this).children(commonVariable.secondaryIcon).addClass(commonVariable.disableIcon)
            }
        }

        /**
         * This function toggle secondary icons
         * @param {any} this icon id
         * @param {any} event event
         */
        function toggleSecondaryIcons(this: any, event: any) {
            event.preventDefault()
            $(this).parent('li').children('ul').slideToggle(commonVariable.twoHundred)
            $(this).toggleClass('open')
            /*
            $(this).children(commonVariable.svghevronUp).toggle()
            $(this).children(commonVariable.svghevronDown).toggle()
            */
        }

        /*
         * Highlighting primary nav Start
         */
        const anchorTagBrandToHighlight = $('.nl-brand-navigation__right-part__listings-item a').filter(function (
            this: any,
        ) {
            const dataPath =
                $(this)
                    ?.attr('href')
                    ?.substring($(this)?.attr('href')?.lastIndexOf('/') as number)
                    .split('.html')[0] || ''
            return window.location.pathname.indexOf(dataPath) > commonVariable.minusOne
        })
        anchorTagBrandToHighlight.addClass('nl-brand-navigation__item-link--active')
        const anchorTagResBrandToHighlight = $('.nl-brand-responsive-menu__brand-menu a').filter(function (this: any) {
            return $(this).attr('href') === window.location.pathname
        })
        anchorTagResBrandToHighlight.addClass('nl-brand-responsive-menu__brand-menuitem__item-link--active')
        const anchorTagHighlightUsingBreadcrumb = $('.nl-brand-navigation__right-part__listings-item a').filter(
            function (this: any) {
                return $(this).attr('href') === $('.cmp-breadcrumb li:nth-child(3) a').attr('href')
            },
        )
        anchorTagHighlightUsingBreadcrumb.addClass('nl-brand-responsive-menu__brand-menuitem__item-link--active')
        // Highlighting primary nav End
    })

    //  showmore start

    $(document).ready(function () {
        $(commonVariable.showMore).hide()
        $(commonVariable.showLess).hide()
        if ($('.nl-brand-dropdownlist__left__menu').length > commonVariable.showResultLimit) {
            $(commonVariable.brandDropdownlistLeftMenu).hide()
            $(commonVariable.showMore).show()
            $(commonVariable.showLess).hide()
        }

        $(commonVariable.showMore).on('click', function () {
            $(commonVariable.showMore).hide()
            $(commonVariable.showLess).show()
            $(commonVariable.brandDropdownlistLeftMenu).toggle()
        })
        $(commonVariable.showLess).on('click', function () {
            $(commonVariable.showMore).show()
            $(commonVariable.showLess).hide()
            $(commonVariable.brandDropdownlistLeftMenu).toggle()
        })
    })

    $(document).ready(function () {
        $(commonVariable.showMoreRight).hide()
        $(commonVariable.showLessRight).hide()
        if ($('.nl-brand-dropdownlist__right__menu').length > commonVariable.showResultLimit) {
            $(commonVariable.brandDropdownlistRightMenu).hide()
            $(commonVariable.showMoreRight).show()
            $(commonVariable.showLessRight).hide()
        }

        $(commonVariable.showMoreRight).on('click', function () {
            $(commonVariable.showMoreRight).hide()
            $(commonVariable.showLessRight).show()
            $(commonVariable.brandDropdownlistRightMenu).toggle()
        })
        $(commonVariable.showLessRight).on('click', function () {
            $(commonVariable.showMoreRight).show()
            $(commonVariable.showLessRight).hide()
            $(commonVariable.brandDropdownlistRightMenu).toggle()
        })
    })
    // show more end

    // account profile responsive
    $('#ourBrandprimaryClick').click(function () {
        $(commonVariable.brandMenuDropDown).addClass('showbrandmenu')
        $('#profileDropDown').removeClass('showbrandmenu')
    })
    $('#profilemenu').click(function () {
        $(commonVariable.brandMenuDropDown).removeClass('showbrandmenu')
        $('#profileDropDown').addClass('showbrandmenu')
    })
    if (window.matchMedia('(max-width: 767px)').matches) {
        $('#profileLogout button')
            .map(function (this: any) {
                const onclick = $(this).attr('onclick')
                $(this).removeAttr('onclick')
                return onclick
            })
            .get()
        $('#profileLogout').click(function () {
            $('.profile-popupmodal').show()
        })
    }

    $('.profilelogout-popupbtn__cancel').click(function () {
        $('.profile-popupmodal').hide()
    })
    // profile end

    $(document).ready(function () {
        $(document).click(function () {
            $('ul#bdsAccountsMenu').hide()
            $(commonVariable.profileListItem).hide()
        })

        $('#profileicon').click(function (event: any) {
            $('#profileList li a').removeClass('active')
            event.stopPropagation()
            $(commonVariable.profileListItem).toggle()
        })
        $('#profileicon li').click(function (event: any) {
            event.stopPropagation()
            $('#profileList').hide()
        })

        $(document).on('scroll', function () {
            $(commonVariable.profileListItem).hide()
        })
    })

    // script for versioncontrol accordion collapse and expand

    const acc = document.getElementsByClassName('cmp-version__button')
    for (let i = 0; i < acc.length; i++) {
        acc[i].addEventListener('click', function (this: any) {
            this.classList.toggle('active')
            this.classList.toggle('addIcon')
            const panel = this.nextElementSibling
            if (panel.style.maxHeight) {
                panel.style.maxHeight = null
            } else {
                panel.style.maxHeight = `${panel.scrollHeight}px`
            }
        })
    }

    if (window.screen.width > commonVariable.mobileWidth) {
        if ($('.landing-bottom-content').is(':visible')) {
            $(commonVariable.landingFourGrid).css('bottom', '192px')
        } else {
            $(commonVariable.landingFourGrid).css('bottom', '38px')
        }
    } else {
        if ($('.landing-bottom-content').is(':visible')) {
            $('.bds-brand-layout__4-grid-card.card-col-lg img').css('height', '309px')
            $('.bds-brand-layout__5-grid-card.card-col-lg img').css('height', '309px')
            $(commonVariable.landingFourGrid).css('bottom', '89px')
            $('.bds-brand-layout__5-grid-card.card-col-lg-long .landing-content').css('bottom', '89px')
            $('.bds-brand-layout__5-grid-card.card-col-lg .landing-content').css('bottom', '89px')
        } else {
            $(commonVariable.landingFourGrid).css('bottom', '0px')
            $('.bds-brand-layout__5-grid-card.card-col-lg-long .landing-content').css('bottom', '0px')
            $('.bds-brand-layout__5-grid-card.card-col-lg .landing-content').css('bottom', '0px')
        }
    }

    const isAccessibleBrandsExists = accessibleBrands => accessibleBrands && accessibleBrands.length > 0

    $(document).ready(function () {
        // Email,Name and Profile Icon From Cookies
        const useInitialName = getCookie('user_initial') ? getCookie('user_initial') : ''
        const userLastName = getCookie('user_last_name') ? getCookie('user_last_name') : ''
        const userEmail = getCookie('user_email') ? getCookie('user_email') : ''
        const userFirstName = getCookie('user_first_name') ? getCookie('user_first_name') : ''

        let brandDropDownList = sessionStorage.getItem('bannerBrands')

        const x = document.getElementsByTagName('html')[0]
        const resultss = x.getAttribute('lang')
        if (sessionStorage.getItem('bannerBrands')) {
            brandDropDownList = sessionStorage.getItem('bannerBrands')
            updateBannerBrand()
        } else {
            const apiUrlBannerBrand = `/bin/bds/accessibleBrands?brand=bb&lang=${resultss}`
            // eslint-disable-next-line no-void
            void $.ajax({
                type: 'GET',
                dataType: 'text',
                timeout: 3000,
                url: apiUrlBannerBrand,
                success: function (bannerBrandResult: any) {
                    if (bannerBrandResult !== null) {
                        const result = $.parseJSON(bannerBrandResult)
                        let dataresultPage = {}
                        dataresultPage = result.accessibleBrands
                        if (isAccessibleBrandsExists(dataresultPage)) {
                            sessionStorage.setItem('bannerBrands', JSON.stringify(dataresultPage))
                            brandDropDownList = sessionStorage.getItem('bannerBrands')
                            updateBannerBrand()
                        }
                    }
                },
            })
        }
        let brandConsumerList = sessionStorage.getItem('consumerBrand')
        if (sessionStorage.getItem('consumerBrand')) {
            brandConsumerList = sessionStorage.getItem('consumerBrand')
            updateConsumerBrand()
        } else {
            const apiUrlConsumerBrand = `/bin/bds/accessibleBrands?brand=cb&lang=${resultss}`
            // eslint-disable-next-line no-void
            void $.ajax({
                type: 'GET',
                dataType: 'text',
                timeout: 3000,
                url: apiUrlConsumerBrand,
                success: function (consumerBrandResult: any) {
                    if (consumerBrandResult !== null) {
                        const results = $.parseJSON(consumerBrandResult)
                        let consumerdataresultPage = {}
                        consumerdataresultPage = results.accessibleBrands
                        if (isAccessibleBrandsExists(consumerdataresultPage)) {
                            sessionStorage.setItem('consumerBrand', JSON.stringify(consumerdataresultPage))
                            brandConsumerList = sessionStorage.getItem('consumerBrand')
                            updateConsumerBrand()
                        }
                    }
                },
            })
        }

        /**
         * This function update banner brand
         */
        function updateBannerBrand() {
            if (brandDropDownList) {
                appendBrandList(brandDropDownList, 'banner-brands-click')
                appendBrandList(brandDropDownList, 'brands-click')
            }
        }

        /**
         * This function update consumer brand
         */
        function updateConsumerBrand() {
            if (brandConsumerList) {
                appendConsumerList(brandConsumerList, 'consumer-brands-click')
                appendConsumerList(brandConsumerList, 'consumer-click')
            }
        }

        /**
         * brand present
         * @param {any} brandListData brandListData
         * @param {any} id listId
         */
        function appendBrandList(brandListData: any, id: any) {
            const obj = $.parseJSON(brandListData)
            $.each(obj, function (_key, value) {
                if (value.brandId !== 'bds' && value.brandId !== 'BDS') {
                    if (id === 'banner-brands-click') {
                        const newHTML = `<li class="nl-brand-dropdownlist__left__menu">
                            <a href=${value.brandPath} class="nl-brand-dropdownlist__left__menuitem brands-item">${value.brandName}</a>
                            </li>`
                        $(`#${id}`).append(newHTML)
                    } else {
                        const mobileHtml = `<li class="nl-brand-dropdownlist__left__brand-listing">
                            <a href=${value.brandPath} class="nl-brand-dropdownlist__left__brand-menuitem brand-item">${value.brandName}</a>
                            </li>`
                        $(`#${id}`).append(mobileHtml)
                    }
                }
            })
        }

        /**
         * consumerBrand present
         * @param {any} brandListData consumerbrandListData
         * @param {any} id listId
         */
        function appendConsumerList(brandListData: any, id: any) {
            const obj = $.parseJSON(brandListData)
            $.each(obj, function (_key, value) {
                if (value.brandId !== 'bds' && value.brandId !== 'BDS') {
                    if (id === 'consumer-brands-click') {
                        const newHTML = `<li class="nl-brand-dropdownlist__right__menu">
                            <a href=${value.brandPath} class="nl-brand-dropdownlist__right__menuitem brands-item">${value.brandName}</a>
                            </li>`
                        $(`#${id}`).append(newHTML)
                    } else {
                        const mobileHtml = `<li class="nl-brand-dropdownlist__right__brand-listing">
                            <a href=${value.brandPath} class="nl-brand-dropdownlist__right__brand-menuitem">${value.brandName}</a>
                            </li>`
                        $(`#${id}`).append(mobileHtml)
                    }
                }
            })
        }

        $('.formProfile input[name="inputTitle"]').val(`${userFirstName} ${userLastName}`)
        $('.formProfile input[name=inputemail]').val(userEmail)
        $('#profileicon .nl-universal-navigation__profile-image').text(useInitialName?.toUpperCase())
        $('#welcomeUser').text(userFirstName)
    })

    /**
     * updating the url for all the image tags to reduce the image file size.
     */
    function akamaiDimensions() {
        const nodeList = document.querySelectorAll('img')
        let currentScreenSize = ''
        // eslint-disable-next-line sonar/no-nested-assignment
        window.outerWidth > commonVariable.mobileWidth ? (currentScreenSize = 'md') : (currentScreenSize = 'xs')
        for (let j = 0; j < nodeList.length; j++) {
            const currentComponent = String(nodeList[j].parentElement?.getAttribute('data-component'))
            if (componentDimensions[currentComponent] !== undefined) {
                if (nodeList[j].attributes['data-src'] === undefined) {
                    continue
                }
                const newDimensions = componentDimensions[currentComponent][currentScreenSize]
                const dimensionProperties = newDimensions.dimension
                const sourceUrl = nodeList[j].attributes['data-src'].value
                nodeList[j].src = sourceUrl.concat(`?im=${newDimensions.name}`)
                if (dimensionProperties) {
                    nodeList[j].src += `&${Object.keys(dimensionProperties)
                        .map(key => `${key}=${dimensionProperties[key]}`)
                        .join('&')}`
                }
            }
        }
    }

    $(document).ready(function () {
        akamaiDimensions()
        getAssetDownloadResults()
    })
    const accessToken = getCookie('access_token') ? getCookie('access_token') : ''
    /**
     * get asset Component in
     */
    function getAssetDownloadResults() {
        const assetComponents = document.getElementsByClassName('nl-assetdownload')
        $('.nl-assetdownload').hide()
        if (assetComponents && assetComponents.length > 0) {
            for (let j = 0; j < assetComponents.length; j++) {
                const assetUrl = assetComponents[j]?.getAttribute('data-url')
                const subscriptionKey = assetComponents[j]?.getAttribute('data-subscriptionkey')
                if (accessToken) {
                    getAssetResult(assetUrl, subscriptionKey, j)
                }
            }
        }
    }

    /**
     * Api Call to get asset result
     * @param {any} dataUrl url for assetdownload
     * @param {any} subscriptionKey subscription key
     * @param {number} index index
     */
    function getAssetResult(dataUrl: any, subscriptionKey: any, index: number) {
        // eslint-disable-next-line no-void
        void $.ajax({
            type: 'GET',
            dataType: 'text',
            url: dataUrl,
            headers: {
                Authorization: accessToken,
                'Access-Control-Allow-Origin': '*',
                'Ocp-Apim-Subscription-Key': subscriptionKey,
            },

            success: function (data) {
                updateUi(index, $.parseJSON(data))
            },
        })
    }

    /**
     * update asset download
     * @param {any} index component index
     * @param {any} data data response
     */
    function updateUi(index: any, data: any) {
        const assetListUi = document.getElementsByClassName('nl-assetdownload')
        const assetName = data.collection_name ? data.collection_name : data.filename
        $(assetListUi[index]).css('display', 'block')
        $(assetListUi[index]).find('.nl-assetdownload-downloadlink a').text(assetName)
        $(assetListUi[index]).find('.nl-assetdownload-date span').text(dateConverter(data.system_last_updated_date))
        const arrayButton = data.system_security_templates ? data.system_security_templates : []
        $(assetListUi[index]).find('.labelList').html('')
        $(arrayButton).each(function (_key, val) {
            const newHtml = `<span class="nl-assetdownload-label">${val}</span>`
            $(assetListUi[index]).find('.labelList').append(newHtml)
        })
    }

    /**
     * date Formatter
     * @param {any} date date in millisecond
     * @return {any} date in mm dd,yyyy format
     */
    function dateConverter(date: any) {
        const dateFormatted = new Date(date).toDateString()
        const dateStrArr = dateFormatted.split(' ')
        return `${dateStrArr[commonVariable.one]} ${dateStrArr[commonVariable.two]},${dateStrArr[commonVariable.three]}`
    }

    /**
     * cookies present
     * @param {any} cName cookies id
     * @return {any} cookies data
     */
    function getCookie(cName: any): any {
        const name = `${cName}=`
        const cDecoded = decodeURIComponent(document.cookie)
        const cArr = cDecoded.split('; ')
        let res = ''
        cArr.forEach(val => {
            if (val.startsWith(name)) {
                res = val.substring(name.length)
            }
        })
        return res
    }

    $(document).on('click', '.logoutLink', function () {
        sessionStorage.removeItem('bannerBrands')
        sessionStorage.removeItem('consumerBrand')
    })
}

export { bdsPageLoadComponent }
